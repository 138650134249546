import { useParams } from 'react-router-dom'
import ReactPlayer from 'react-player/lazy'
import { getWorkBySlug } from '../../data/data'

export default function WorkCard() {
  let params = useParams()
  let work = getWorkBySlug(params.slug)

  return (
    <div className="work-detail">
      <h3>{work.companyName}</h3>
      <p>{work.description}</p>
      {work.examples.map((example, i) => {
        return (
          <article key={i}>
            <h4>{example.title}</h4>
            <p>{example.description}</p>
            <img
              src={example.image}
              alt="device mockup of puppyspot consumer website"
            />
            <ReactPlayer
              url={example.video}
              controls={true}
              width="auto"
              className="work-video"
            />
          </article>
        )
      })}
    </div>
  )
}
