import React from 'react'
import { Link } from 'react-router-dom'
import Headshot from '../../../resources/images/about/about.jpg'

export default function Blurb() {
  return (
    <article className="about-content">
      <div className="about-details">
        <img src={Headshot} alt="a code goblin" />
        <div>
          <p className="about-blurb">
            Hello friends! My name is Geanie and I'm a New York City based
            frontend engineer.
          </p>
          <p className="list">✧⋄⋆⋅⋆⋄✧⋄⋆⋅⋆⋄✧ I can help you with...</p>
          <ul>
            <li>Single Page Application development</li>
            <li>User Experience optimization</li>
            <li>Internal code cleanup & optimization</li>
            <li>Accessibility & device responsiveness</li>
          </ul>
          <p className="list">✧⋄⋆⋅⋆⋄✧⋄⋆⋅⋆⋄✧ I can't help you with...</p>
          <ul>
            <li>Rocket reentry equations</li>
            <li>Undertanding why there were polar bears on The Island</li>
          </ul>
        </div>
      </div>
      <p>✧⋄⋆⋅⋆⋄✧⋄⋆⋅⋆⋄✧⋄⋆⋅⋆⋄✧⋄⋆⋅⋆⋄✧⋄⋆⋅⋆⋄✧⋄⋆⋅⋆⋄✧</p>
      <div className="about-links">
        <Link to={'/about/experience'}>Experience</Link>
        <Link to={'/about/awards'}>Awards</Link>
        <Link to={'/about/education'}>Education</Link>
        <Link to={'/about/skills'}>Skills</Link>
      </div>
    </article>
  )
}
