import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import '../resources/scss/elements/_window.scss'

export default function Window(props) {
  const [isFullScreen, setIsFullScreen] = useState()
  const location = useLocation()

  useEffect(() => {
    if (isFullScreen) setIsFullScreen(true)
  }, [location, isFullScreen])

  const toggleFullScreen = () => {
    if (isFullScreen) setIsFullScreen(false)
    else setIsFullScreen(true)
  }

  return (
    <div
      className="window"
      fullscreen={!isFullScreen ? 'false' : isFullScreen.toString()}
    >
      <div className="window__bar">
        <div className="window__bar-text">
          <p>{props.barTitle.toUpperCase()}</p>
        </div>
        <div className="window__bar-icons">
          <button className="min" disabled></button>
          <button
            className="max"
            onClick={(e) => {
              e.preventDefault()
              toggleFullScreen(true)
            }}
          ></button>
          <button className="close" disabled></button>
        </div>
      </div>
      <div className="window__content">{props.children}</div>
    </div>
  )
}
