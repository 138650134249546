import { useState } from 'react'
import { Window } from '../../elements'
import '../../resources/scss/pages/contact.scss'

export default function Contact() {
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('')
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [formValid, setformValid] = useState(false)
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false)
  const [showVisitorMessage, setShowVisitorMessage] = useState(false)
  const [showGoodbyeMessage, setShowGoodbyeMessage] = useState(false)
  const [isMessageSent, setIsMessageSent] = useState(false)
  const emailValidationRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

  setTimeout(() => {
    setShowWelcomeMessage(true)
  }, 2000)

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&')
  }

  const handleSubmitClick = (e) => {
    e.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contactForm', name, email, message }),
    })
      .then(() => {
        setShowVisitorMessage(true)
        setIsMessageSent(true)
        setTimeout(() => {
          setShowGoodbyeMessage(true)
        }, 500)
      })
      .catch((error) => console.log(error))
  }

  const validate = {
    name: (name) => {
      if (name !== '') return true
      else return false
    },
    email: (email) => {
      if (email === '') return false
      if (email.match(emailValidationRegex)) return true
      else return false
    },
    message: (message) => {
      if (message) return true
      else return false
    },
    form: () => {
      if (
        !nameError &&
        !emailError &&
        !messageError &&
        name &&
        email &&
        message
      )
        setformValid(true)
    },
  }

  const handleNameInput = (nameInput) => {
    setName(nameInput.value)
    const validatedData = validate.name(nameInput.value)

    if (!validatedData) {
      nameInput.setAttribute('error', true)
      setNameError('Name required')
    } else {
      nameInput.setAttribute('error', false)
      setNameError('')
      validate.form()
    }
  }

  const handleEmailInput = (emailInput) => {
    setEmail(emailInput.value)
    const validatedData = validate.email(emailInput.value)

    if (!validatedData) {
      emailInput.setAttribute('error', true)
      setEmailError('Enter valid email')
    } else {
      emailInput.setAttribute('error', false)
      setEmailError('')
      validate.form()
    }
  }

  const handleMessageInput = (messageInput) => {
    setMessage(messageInput.value)
    const validatedData = validate.message(messageInput.value)

    if (!validatedData) {
      messageInput.setAttribute('error', true)
      setMessageError('Please add a message')
    } else {
      messageInput.setAttribute('error', false)
      setMessageError('')
      validate.form()
    }
  }

  const contactForm = () => {
    return (
      <form className="contactForm" onSubmit={(e) => handleSubmitClick(e)}>
        <div className="contact-details">
          <div>
            <input
              type="text"
              name="name"
              className="name"
              value={name}
              placeholder="Name"
              onChange={(e) => setName(e.target.value)}
              onBlur={(e) => handleNameInput(e.target)}
              error=""
            />
            <span>{nameError}</span>
          </div>
          <div>
            <input
              type="email"
              name="email"
              className="email"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              onBlur={(e) => handleEmailInput(e.target)}
              error=""
            />
            <span>{emailError}</span>
          </div>
        </div>
        <div className="contact-message">
          <textarea
            name="message"
            placeholder="Messaage"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onBlur={(e) => handleMessageInput(e.target)}
            error=""
          ></textarea>
          <span>{messageError}</span>
        </div>
        <button
          type="submit"
          className="button"
          disabled={formValid ? true : false}
        >
          Submit
        </button>
      </form>
    )
  }

  const successMessage = () => {
    return <p>Message Sent</p>
  }

  return (
    <Window barTitle="Let's Chat">
      <section className="contact-content">
        <div className="message-display">
          <p
            className="message"
            isvisible={showWelcomeMessage ? 'true' : 'false'}
          >
            <span>GeanieBot: </span>Hello there! Thanks for stopping by! I look
            forward to chatting with you about your exciting project or other
            opportunities.
          </p>
          <p
            className="message"
            isvisible={showVisitorMessage ? 'true' : 'false'}
          >
            <span>{name}:</span>
            {message}
          </p>
          <p
            className="message"
            isvisible={showGoodbyeMessage ? 'true' : 'false'}
          >
            <span>GeanieBot:</span>
            I'll get back to you ASAP! TTFN &lt;3
          </p>
        </div>
        {isMessageSent ? successMessage() : contactForm()}
      </section>
    </Window>
  )
}
